import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import GoatSale from '../../components/animal-shop/goat-sale'
import TopBunerProduct from '../../components/top-buner/top-baner-product';



const AlpinePoroda = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Козы в продаже', url:'#', status:true}]

    const indicators = []

  
  return (
    <div>
        <SEO title="Козы в продаже"
            description="Козы в продаже">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Козы в продаже'}
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={'descrition-page'}
                      breadCrumbs={breadCramb}
                      indicators={indicators}>
            </TopBunerProduct>
        <Container fluid>
            <GoatSale />
        </Container>
      </Layout>
      
    </div>
  );
};

export default AlpinePoroda;