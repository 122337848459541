import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import GotAnimals from '../../services/gotAnimals'
import ItemCard  from './itemCard/itemCard';
import FilterPanel from './filtesPanel/filtesPanel'
import './animal-shop.css';
import Spinner from '../spinner/spinner'





export default class GoatSale extends React.Component {


    state = {
        actualFilter: "all",
        filters: [
            {name:'ВСЕ ПОРОДЫ', filter:'all', ischoise: true},
            {name:'ЗААНЕНСКИЕ', filter:'zaanen', ischoise: false},
            {name:'БУРСКИЕ', filter:'bursk', ischoise: false},
            {name:'ТОГГЕНБУРГСКИЕ', filter:'toggy', ischoise: false},
            {name:'НУБИЙСКИЕ ', filter:'nubian', ischoise: false},
            {name:'АЛЬПИЙСКИЕ', filter:'alpine', ischoise: false}
        ],
        deviceType: 'desctop'
    }

    GetAnimals = new GotAnimals();

    componentDidMount() {
        if(window.innerWidth < 460) {
            this.setState({deviceType:'mobile'})
        }
    }

    onUpdateActualFilter = (actual) => {
        const {filters, deviceType} = this.state;

        const indexOfSelected = filters.findIndex(elem => {
            return elem.ischoise === true;
        })
        const newElem = filters[indexOfSelected]
        newElem.ischoise = false;
        const newState = [...filters.slice(0, indexOfSelected), newElem, ...filters.slice(indexOfSelected + 1, filters.length)]
        console.log(newState)
        console.log('Actual', actual)

        const indexOfSelect = filters.findIndex(elem => {
            return elem.filter === actual;
        })
        const selectedElement = newState[indexOfSelect];
        console.log(selectedElement)
        selectedElement.ischoise = true;
        const newfiltersState = [...newState.slice(0, indexOfSelect), selectedElement, ...newState.slice(indexOfSelect + 1, newState.length)];
        this.setState({
            filters: newfiltersState,
        })
    }


    render() {
const {actualBreed, filters, deviceType} = this.state
       const content = () => {
        if(this.GetAnimals.getAllGoat().length !== 0) {
            const listAnimals = this.GetAnimals.getAllGoat().map((item, i) => {
                if(filters[filters.findIndex(elem => elem.ischoise === true)].filter === 'all') {
                    return (
                        <ItemCard key={i} item={item} />
                    )
                } else if (item.type === filters[filters.findIndex(elem => elem.ischoise === true)].filter){
                    return (
                        <ItemCard key={i} item={item} />
                     )
                } else {
                    return null
                }
            })
           
            const countItems = (items) => {
                let count = 0;
                items.forEach((elem) => {
                   return elem !== null? count ++: null
                });
                return count
            }
            const vicibleItems = countItems(listAnimals) !== 0? listAnimals: (
                <div >
                    <div className="text-center m-5">
                        <h4>По выбранным вами параметрам животные не найдены</h4>
                    </div>
                    <div className="text-center" style={{fontSize: "18px"}}>
                        <p>Чтобы уточнить о наличии в продаже животных или заказать коз нужной вам породы свяжитесь с нами по телефону</p>
                        <p><a href="tel:+79814572668" className="phone-text"><span>+7(981)4572668</span></a></p>
    
                    </div>
                    <div>
                        <Spinner />
                    </div>
                </div>

            )
            return vicibleItems
        } else {
            return (
                <div >
                    <div className="text-center m-5">
                        <h4>В настоящее время коз в продаже нет.</h4>
                    </div>
                    <div className="text-center" style={{fontSize: "18px"}}>
                        <p>Чтобы заказать коз нужной вам породы свяжитесь с нами по телефону</p>
                        <p><a href="tel:+79814572668" className="phone-text"><span>+7(981)4572668</span></a></p>
    
                    </div>
                </div>
            )
        }
       }

        return (
           <Row className="shop-animals">
            <Col md="2" className="sticky-top filter-panel">
                <FilterPanel 
                            filters={filters}
                            onUpdateActualFilter={this.onUpdateActualFilter}
                            deviceType={deviceType} />
            </Col>
            <Col md="10">{content()}</Col>
           </Row>
        )
    }
}